import { useState } from 'react';
import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "@/components/ui/select"
import { Check, ChevronsUpDown } from "lucide-react"
import { UseFormReturn } from 'react-hook-form';
import locationData from '../data/location.json'
import { Label } from '@/components/ui/label';

interface CitySelectionProps {
  form: UseFormReturn<any>;
}

interface CountryData {
  [country: string]: Array<{
    state?: string;
    province?: string;
    cities: string[];
  }>;
}

const countryData: CountryData = locationData

const US_STATUSES = ["US Citizen", "Green Card", "EAD", "Valid Visa with Work Rights"] as const
const CANADA_STATUSES = ["Canadian Citizen", "Permanent Resident", "Valid Work Permit"] as const

export function LocationAndWorkAuth({ form }: CitySelectionProps) {
    const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
    
    // Flatten country, state/province, and city data into one list of options
    const flattenedLocations = Object.entries(countryData).flatMap(([country, regions]) => 
      regions.flatMap(region => 
        region.cities.map(city => 
          `${city}, ${region.state || region.province}, ${country}`
        )
      )
    );

  return (
    <>
      <div className="space-y-2">
      <FormField 
        control={form.control} 
        name="location" 
        rules={{ required: "Location is required" }}
        render={({ field }) => (
        <FormItem className="flex flex-col">
          <FormLabel>Location</FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  role="combobox"
                  className="w-full justify-between"
                >
                  {field.value ? field.value : "Select location"}
                  <ChevronsUpDown className="opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-full p-0 bg-white">
              <Command>
                <CommandInput placeholder="Search location..." className="h-9" />
                <CommandList>
                  <CommandEmpty>No location found.</CommandEmpty>
                  <CommandGroup>
                    {flattenedLocations.map((location, index) => (
                      <CommandItem
                        key={index}
                        value={location}
                        onSelect={() => {
                          setSelectedLocation(location);
                          form.setValue("location", location);
                        }}
                      >
                        {location}
                        <Check
                          className={location === field.value ? "opacity-100 ml-auto" : "opacity-0 ml-auto"}
                        />
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage className="text-red-500"/>
        </FormItem>
      )} />
      </div>

      {form.watch("location") && (
        <>
        <div className="space-y-2">
          <FormField
            control={form.control}
            name="workAuthorization.status"
            render={({field}) => (
              <FormItem>
                <Label htmlFor="workAuthorization.status">Current citizenship status</Label>
                <Select onValueChange={field.onChange} defaultValue={field.value} required>
                  <FormControl>
                    <SelectTrigger className={`${
                      form.formState.errors["workAuthorization.status"]
                        ? "border-2 border-red-500"
                        : "border-primary100"
                      }`}>
                      <SelectValue placeholder="Select your current status"/>
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {
                      selectedLocation?.split(', ')[2] === "Canada" && (
                        CANADA_STATUSES.map((status)=>(
                          <SelectItem value={status}>{status}</SelectItem>
                        ))
                      )
                    }
                    {
                      selectedLocation?.split(', ')[2] === "United States" && (
                        US_STATUSES.map((status)=>(
                          <SelectItem value={status}>{status}</SelectItem>
                        ))
                      )
                    }
                    {
                      ((selectedLocation?.split(', ')[2] !== "Canada")&&(selectedLocation?.split(', ')[2] !== "United States")) && (
                        <SelectItem value="Any legal work authorization">Other</SelectItem>
                      )
                    }     
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <Label htmlFor="workAuthorization.requiresSponsorship">Do you require sponsorship?</Label>
            <input 
              type='checkbox' 
              {...form.register("workAuthorization.requiresSponsorship")}
              className='mx-2'
              />
          </div>
          <div className="space-y-2">
            <Label htmlFor="workAuthorization.hasSecurityClearance">Do you have a security clearance?</Label>
            <input 
              type='checkbox' 
              {...form.register("workAuthorization.hasSecurityClearance")}
              className='mx-2'
              />
          </div>
        </div>
        </>
        )
      }
    </>
  );
}
