import { CardHeader, CardTitle, CardDescription, Card, CardContent } from "@/components/ui/card"
import { Label } from "@/components/ui/label"
import { Input } from "@/components/ui/input"
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "@/components/ui/select"
import { Button } from "@/components/ui/button"
import { useForm } from "react-hook-form"
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form"
import { useNavigate } from "@tanstack/react-router"
import { toast } from "sonner"
import { Application, PostApplyBody } from "../../../openapi/api/model"
import { useGetCampaignDetails, usePostApplication } from "./utils/hooks"
import { useState } from "react"
import { CustomAxiosError } from "../Authentication/AuthComponents/ResumeSignUp"
import { LocationAndWorkAuth } from "./components/LocationAndWorkAuthorization"
//import { capitalizeFirstLetter } from "@/utils/Functions/Functions"


const ApplicationForm = ({hiringCampaignId}:{hiringCampaignId?:string}) => {

  const navigate = useNavigate({from: '/'})
  const [disabled, setDisabled] = useState(false)
  const {data} = useGetCampaignDetails({id:hiringCampaignId??'',enabled:!!hiringCampaignId})
  const {mutate:application} = usePostApplication({
    onSuccess:()=>{
      navigate({to: '/apply/$hiringcampaignId/submitted'})
    },
    onError: (error)=>{
      toast.error("Failed to submit application!",{
        description: `${(error as CustomAxiosError)?.response?.data.error}`,
        action: {
          label: "Dismiss",
          onClick: () => {},
        },
        style: {
          background: '#dc2626'
        }
      })
    },
    onMutate: ()=>{
      setDisabled(true)
    },
    onSettled: ()=>{
      setDisabled(false)
    }
  })
  
  const form = useForm({})

  const onSubmit = async(data:any) => {
    //const data = data as ApplicationFormValues
    console.log('Data: ',data)
      const formData = new FormData()
      formData.append('formData',JSON.stringify({
        firstName: data.firstName??'', 
        middleName: data.middleName??'', 
        lastName: data.lastName??'',
        streetAddress: data.streetAddress??'', 
        email: data.email??'', 
        phoneNumber: data.phoneNumber??'', 
        city: data.location.split(', ')[0]??'', 
        state: data.location.split(', ')[1]??'',
        country: data.location.split(', ')[2]??'',
        zipCode: data.zipCode??'', 
        dateOfBirth: data.dateOfBirth??'', 
        positionAppliedFor: data.positionAppliedFor??'', 
        desiredSalary: data.desiredSalary??'', 
        availableStartDate: data.availableStartDate??'', 
        employmentType: data.employmentType??'', 
        howDidYouHear: data.howDidYouHear??'', 
        currentEmployer: data.currentEmployer??'', 
        jobTitle: data.jobTitle??'', 
        datesOfEmployment: {
          startDate: data.datesOfEmployment?.startDate??'', 
          endDate: data.datesOfEmployment?.endDate??'' 
        },
        responsibilities: data.responsibilities??'', 
        reasonForLeaving: data.reasonForLeaving??'', 
        educationLevel: data.educationLevel??'', 
        universityName: data.universityName??'', 
        aboutYou: data.aboutYou??'', 
        hiringCampaignId: hiringCampaignId??'',
        workAuthorization: {
          requiresSponsorship: data.workAuthorization.requiresSponsorship,
          acceptedStatuses: data.workAuthorization.status,
          hasSecurityClearance: data.workAuthorization.hasSecurityClearance,
        }
      } as Application))

      // Append file
      if (data.resume && data.resume[0]) {
        formData.append('resume',data.resume[0]);
      }

      //mutate({data:formData as PostApplyBody})
      application({formData:formData as PostApplyBody})
  }

  return (
    <div className="w-full min-h-screen flex flex-col">
      <header className="bg-blue100 text-white py-8 px-4 sm:px-8">
        <div className="container max-w-2xl mx-auto">
          <CardHeader>
            <CardTitle className="text-2xl">
              {data ? `${data.position} - ${data.companyName}` : 'Job Application'}
            </CardTitle>
            <CardDescription>Fill out the form below to apply for the position.</CardDescription>
          </CardHeader>
        </div>
      </header>
      <div className="flex-1 py-8 px-4 sm:px-8">
        <Card className="w-full max-w-2xl mx-auto">
          <CardContent>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="grid gap-4 py-8">
              <div className="grid grid-cols-3 gap-4">
                <div className="space-y-2">
                  <FormField
                    control={form.control}
                    name="firstName"
                    render={()=>(
                      <FormItem>
                        <Label htmlFor="firstName">First Name</Label>
                        <FormControl>
                          <Input id="firstName" placeholder="John" {...form.register("firstName")} className={`${
                          form.formState.errors["firstName"]
                            ? "border-2 border-red-500"
                            : "border-primary100"
                        }`} required/>
                        </FormControl>
                      </FormItem> 
                    )}
                  />
                </div>
                <div className="space-y-2">
                <FormField
                  control={form.control}
                  name="middleName"
                  render={()=>(
                    <FormItem>
                      <Label htmlFor="middleName">Middle Name</Label>
                      <FormControl>
                        <Input id="middleName" placeholder="Q." {...form.register("middleName")}/>
                      </FormControl>
                    </FormItem> 
                  )}
                  />
                </div>
                <div className="space-y-2">
                  <FormField
                    control={form.control}
                    name="lastName"
                    render={()=>(
                      <FormItem>
                        <Label htmlFor="lastName">Last Name</Label>
                        <FormControl>
                          <Input id="lastName" placeholder="Doe" {...form.register("lastName")} className={`${
                          form.formState.errors["lastName"]
                            ? "border-2 border-red-500"
                            : "border-primary100"
                        }`} required/>
                        </FormControl>
                      </FormItem> 
                    )}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <FormField
                    control={form.control}
                    name="phoneNumber"
                    render={()=>(
                      <FormItem>
                        <Label htmlFor="phoneNumber">Phone Number</Label>
                        <FormControl>
                          <Input id="phoneNumber" type="tel" placeholder="(123) 456-7890" {...form.register("phoneNumber")} className={`${
                          form.formState.errors["phoneNumber"]
                            ? "border-2 border-red-500"
                            : "border-primary100"
                        }`} required/>
                        </FormControl>
                      </FormItem> 
                    )}
                  />
                </div>
                <div className="space-y-2">
                  <FormField
                    control={form.control}
                    name="email"
                    render={()=>(
                      <FormItem>
                        <Label htmlFor="email">Email Address</Label>
                        <FormControl>
                          <Input id="email" type="email" placeholder="john@example.com" {...form.register("email")} className={`${
                          form.formState.errors["email"]
                            ? "border-2 border-red-500"
                            : "border-primary100"
                        }`} required/>
                        </FormControl>
                      </FormItem> 
                    )}
                  />
                </div>
              </div>
              <div className="space-y-2">
                <FormField
                  control={form.control}
                  name="availableStartDate"
                  render={()=>(
                    <FormItem>
                      <Label htmlFor="availableStartDate">Available Start Date</Label>
                      <FormControl>
                        <Input id="availableStartDate" type="date" {...form.register("availableStartDate")} className={`${
                          form.formState.errors["availableStartDate"]
                            ? "border-2 border-red-500"
                            : "border-primary100"
                        }`} required/>
                      </FormControl>
                    </FormItem> 
                  )}
                />
              </div>
              <div className="space-y-2">
              <FormField
                  control={form.control}
                  name="educationLevel"
                  render={({field}) => (
                    <FormItem>
                      <Select onValueChange={field.onChange} defaultValue={field.value} required>
                        <FormControl>
                          <SelectTrigger className={`${
                            form.formState.errors["educationLevel"]
                              ? "border-2 border-red-500"
                              : "border-primary100"
                            }`}>
                            <SelectValue placeholder="Select highest level of education" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="high-school">High School</SelectItem>
                          <SelectItem value="associate">Associate's</SelectItem>
                          <SelectItem value="bachelor">Bachelor's</SelectItem>
                          <SelectItem value="master">Master's Degree</SelectItem>
                          <SelectItem value="doctorate">Doctorate</SelectItem>
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                />
              </div>
              <div className="space-y-2">
                <FormField
                  control={form.control}
                  name="universityName"
                  render={()=>(
                    <FormItem>
                      <Label htmlFor="universityName">University Name</Label>
                      <FormControl>
                        <Input id="universityName" placeholder="University Name" {...form.register("universityName")} className={`${
                        form.formState.errors["universityName"]
                          ? "border-2 border-red-500"
                          : "border-primary100"
                        }`} required/>
                      </FormControl>
                    </FormItem> 
                  )}
                />
              </div> 
              <div className="grid grid-cols-1 gap-4">
                <LocationAndWorkAuth form={form}/> 
              </div>
              <div className="space-y-2">
                <Label htmlFor="resume">Resume</Label>
                <FormField
                  control={form.control}
                  name="resume"
                  render={()=>(
                    <FormItem>
                      <FormControl>
                        <Input id="resume" type="file" accept=".docx,.pdf" {...form.register("resume")} className={`${
                        form.formState.errors["resume"]
                          ? "border-2 border-red-500"
                          : "border-primary100"
                        }`} required/>
                      </FormControl>
                    </FormItem> 
                  )}
                />
              </div>
              <Button type="submit" className="ml-auto" disabled={disabled}>
                Submit Application
              </Button>
            </form>
            </Form>
          </CardContent>
        </Card>
      </div>
      
    </div>
  )
}

export default ApplicationForm;