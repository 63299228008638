import { Toaster } from "sonner"
import { MainAppRoute } from '@/rootRoutes/MainApp';
import { Route, useBlocker, useNavigate, useParams } from '@tanstack/react-router';
import { useContext, useEffect, useState } from 'react';
import ChatEvaluation from './Chat/Chat';
import { TrackContext } from "@/store/track-context";
//import { ChatSocket } from "@/utils/Websocket/socket";
import LoadingComponent from "./LoadingComponent";

export const BeginQATestPage= ()=>{
  const {courseId,skill} = useParams({from:'/'});
  const navigate = useNavigate({from:'/'})
  const {track} = useContext(TrackContext)
  const [isBlockerActive, setIsBlockerActive] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [_hasRefreshed, setHasRefreshed] = useState(false);

    
  const disableBlocker = () => {
    setIsBlockerActive(false);
  }

  useEffect(() => {
    if (!isBlockerActive) {
      // Navigate to the overview page after blocker is disabled
      navigate({to:'/overview'});
    }
  }, [isBlockerActive, navigate]);

  useBlocker({
    blockerFn:async () => {
      if (!isBlockerActive) return true;
      const confirmLeave=window.confirm('Are you sure you want to leave? This would terminate the evaluation')
      if(confirmLeave){
        setIsSubmitting(true)
        // Simulating an API call
        await new Promise(resolve => setTimeout(resolve, 3000))
        setIsSubmitting(false)
      }
      return confirmLeave
    },
    condition:isBlockerActive
  })


  useEffect(() => {
    const refreshed = localStorage.getItem('hasRefreshed');
    
    if (refreshed === 'true') {
      setHasRefreshed(true); // Update state if the page was refreshed
      disableBlocker()
      console.log('Refreshed')
    } else {
      localStorage.setItem('hasRefreshed', 'true');
      console.log('Loaded')
    }

    return () => {
      localStorage.removeItem('hasRefreshed');
    };
  }, []);



  useEffect(() => {
    const preventBackNavigation = async () => {

      try {
        console.log('Submitting evaluation...');
        navigate({to:'/overview'}) 
      } catch (err) {
        console.error('Error during submission:', err);
      }

      window.history.pushState(null, '', window.location.href);
      console.log('Leaving page...');
    };

    window.addEventListener('popstate', preventBackNavigation);
    window.history.pushState(null, '', window.location.href);

    return () => {
      window.removeEventListener('popstate', preventBackNavigation);
    };
  }, [navigate]);

  return(
  <div className="flex bg-gray-100">
    <div>
    <LoadingComponent 
      isSubmitting={isSubmitting}  
      loadingTitle="Submitting Assignment"
      loadingMessage="Please wait while we process your submission. This may take a few moments."/>
    </div>
    <ChatEvaluation sub={track?.sub??''} projectId={courseId} trackId={track?._id??''} hiringCampaignId={track?.hiringCampaignId??''} skill={skill} blockerFn={disableBlocker}/>
    <Toaster/>
  </div>
  )
}


const BeginQATestRoute = new Route({
  getParentRoute: () => MainAppRoute,
  path: "course/$courseId/$skill/QA/$templateId",
  component: BeginQATestPage,
})

export default BeginQATestRoute;